<template>
  <el-dialog :title="title" :close-on-click-modal="false" append-to-body :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
      label-width="80px">
      <el-form-item label="支公司">
        <template>
          <org :value="dataForm.pareaCode" />
        </template>
      </el-form-item>
      <el-form-item label="处理人" prop="dealUser">
        <!-- <el-input v-model="dataForm.dealUser" placeholder="处理人"></el-input> -->
        <template>
          <user :value="dataForm.dealUser" />
        </template>
      </el-form-item>
      <el-form-item label="处理结果" prop="dealResult">
        <el-input v-model="dataForm.dealResult" type="textarea" :rows="4" :disabled="disabled"
          placeholder="请输入内容" maxlength="300" ></el-input>
        <span style="position: absolute; right: 10px; bottom: 0;">{{ dataForm.dealResult ? dataForm.dealResult.length : 0 }}/300</span>
      </el-form-item>
      <el-form-item label="处理文件" prop="files">
        <fileUpload :readOnly="fileUploadReadOnly" v-model="dataForm.files" :limit="limit" accept=".docx"> </fileUpload>
      </el-form-item>
      <el-form-item label="录音" v-if="lyBool">
        <!-- <el-button type="primary" @click="luyin()" v-if="lyBool">录音</el-button> -->
        <el-button type="primary" v-if="lyBool" @click="handleStart">开始录音</el-button>
        <el-button type="primary" v-if="lyBool" @click="uploadRecord">停止录音并上传</el-button>
      </el-form-item>
      <el-form-item v-if="audioBool">
        <audio ref="audio" v-if="audioBool" @pause="onPause" @play="onPlay" @timeupdate="onTimeupdate"
          @loadedmetadata="onLoadedmetadata" :src="dataForm.recordFilePath" controls="controls"
          controlsList="nodownload">
        </audio>
        <div class="audioClass">
          <el-button type="danger" icon="el-icon-delete" circle @click="deleteRecord" v-if="audioBool"
            :disabled="disabledBool"></el-button>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-loading="isOnSubmit" v-if="bool">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :disabled="isOnSubmit">确定</el-button>
    </span>
    <!-- <record v-if="luyinVisible" ref="luyin"></record> -->
  </el-dialog>

</template>
<script>
import fileUpload from '@/components/fileupload'
import Recorder from 'js-audio-recorder'
import { uploadFile, deleteFile } from '@/api/sys/upload'
// import record from './record';
function realFormatSecond(second) {
  var secondType = typeof second

  if (secondType === 'number' || secondType === 'string') {
    second = parseInt(second)

    var hours = Math.floor(second / 3600)
    second = second - hours * 3600
    var mimute = Math.floor(second / 60)
    second = second - mimute * 60

    return hours + ':' + ('0' + mimute).slice(-2) + ':' + ('0' + second).slice(-2)
  } else {
    return '0:00:00'
  }
}
export default {
  data() {
    return {
      visible: false,
      isOnSubmit: false,
      luyinVisible: false,
      bool: true,
      lyBool: true,
      dialogVisible: false,
      bofangVisible: false,
      disabled: false,
      limit: 1,
      recorder: null,
      playTime: 0,
      timer: null,
      src: null,
      recordFiles: '',
      disabledBool: false,
      fileUploadReadOnly: false,
      dataForm: {
        id: null,
        pareaCode: '',
        dealUser: '',
        dealResult: '',
        dealFilePath: '',
        recordFilePath: '',
        files: []
      },
      audio: {
        // 该字段是音频是否处于播放状态的属性
        playing: false,
        // 音频当前播放时长
        currentTime: 0,
        // 音频最大播放时长
        maxTime: 0
      },
      title: '',
      // dataRule: {
      //   dealResult: [
      //     { required: true, message: '处理结果不能为空', trigger: 'blur' }
      //   ]
      // }
    }
  },
  components: {
    fileUpload
  },
  computed: {
    dataRule() {
      // (1)验证非空白字符正则
      const notnull = /\s*\S+?/
      return {
        dealResult: [ // 处理结果
          { required: true, message: '处理结果不能为空', trigger: 'blur' },
          // (2)通过pattern来设置验证
          { pattern: notnull, message: '请输入非空字符', trigger: 'blur' }
        ]
      }
    },

    regionId: {
      get() {
        const newregionId = this.$store.state.user.regionId;
        if (newregionId !== undefined) {
          return Number(newregionId);
        } else {
          return ''
        }
      }
    },
    gridId: {
      get() {
        const newgridId = this.$store.state.user.gridId;
        if (newgridId !== undefined) {
          return Number(newgridId);
        } else {
          return ''
        }
      }
    },
    userName: {
      get() {
        const uName = this.$store.state.user.name;
        if (uName !== undefined) {
          return String(uName);
        } else {
          return ''
        }
      }
    },
    userId: {
      get() {
        const uID = this.$store.state.user.id;
        if (uID !== undefined) {
          return String(uID);
        } else {
          return ''
        }
      }
    },
    audioBool: function () {
      if (this.dataForm.recordFilePath != null && this.dataForm.recordFilePath != '') {
        return true;
      } else {
        return false;
      }
    }
  },
  filters: {
    // 使用组件过滤器来动态改变按钮的显示
    transPlayPause(value) {
      return value ? '暂停' : '播放'
    },
    // 将整数转化成时分秒
    formatSecond(second = 0) {
      return realFormatSecond(second)
    }
  },
  mounted() {
    setTimeout(() => {
      const audio = this.$refs.audio
      // 获取音频的dom节点
      this.targetNode = this.$refs['progress-red-con']
      if (audio) {
        // 录音资源
        audio.src = this.dataForm.recordFilePath
        audio.load()
        audio.oncanplay = () => {
          // 获取录音的时间长度
          this.audioPalyTime = Math.round(audio.duration)
          this.audioTime = Math.round(audio.duration)
        }
      }
    }, 500)
  },
  created() {
    this.recorder = new Recorder()
  },

  methods: {
    init(id, deal) {
      this.dataForm.id = id
      this.visible = true
      this.isOnSubmit = false
      this.disabled = false
      this.lyBool = true
      this.disabledBool = false
      if (deal == '01') {
        this.title = '处理异常'
        this.bool = true
        this.fileUploadReadOnly = false
      } else {
        this.title = '处理信息'
        this.bool = false
        this.disabled = true
        this.lyBool = false
        this.disabledBool = true
        this.fileUploadReadOnly = true
        // if(deal=='03'){
        //   this.fileUploadReadOnly=true
        // }
      }
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
          this.$http({
            url: this.$http.adornUrl(`/dataflow/olfmajorwarining/info/${this.dataForm.id}`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({ data }) => {
            if (data && data.code === 0) {
              var val = data.data.dealFilePath
              this.dataForm.pareaCode = data.data.pareaCode
              this.dataForm.dealUser = data.data.dealUser
              this.dataForm.dealResult = data.data.dealResult
              this.dataForm.files = val != null && val != '' && val != undefined ? data.data.dealFilePath.split(',') : []
              this.dataForm.recordFilePath = data.data.recordFilePath
              if (this.dataForm.dealUser == null || this.dataForm.dealUser == '' || this.dataForm.dealUser == undefined) {
                this.dataForm.dealUser = this.userId
              }
            }
          })
        }
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.isOnSubmit = true
          this.$http({
            url: this.$http.adornUrl(`/dataflow/olfmajorwarining/update`),
            method: 'post',
            data: this.$http.adornData({
              'id': this.dataForm.id || undefined,
              'dealUser': this.dataForm.dealUser,
              'dealResult': this.dataForm.dealResult,
              'files': this.dataForm.files,
              'areaCode': this.regionId,
              'recordFilePath': this.dataForm.recordFilePath

            })
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 500
              })
              this.visible = false
              this.$emit('refreshDataList')
            } else {
              this.$message.error(data.msg)
            }
          }).finally(() => {
            this.isOnSubmit = false
          })
        }
      })
    },
    // 开始录音
    handleStart() {
      this.recorder = new Recorder()
      Recorder.getPermission().then(() => {
        console.log('开始录音')
        this.$message({
          message: '开始录音',
          type: 'success',
          duration: 500
        })
        this.recorder.start() // 开始录音
      }, (error) => {
        this.$message({
          message: '请先允许该网页使用麦克风',
          type: 'info'
        })
        console.log(`${error.name} : ${error.message}`)
      })
    },
    uploadRecord() {
      if (this.recorder == null || this.recorder.duration === 0) {
        this.$message({
          message: '请先录音',
          type: 'error'
        })
        return false
      }
      this.recorder.pause() // 暂停录音
      this.timer = null
      console.log('上传录音')// 上传录音

      const formData = new FormData()
      const blob = this.recorder.getWAVBlob()// 获取wav格式音频数据
      // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
      const newbolb = new Blob([blob], { type: 'audio/wav' })
      // //此处将mav转为mp3格式
      // const mp3Blob = this.convertToMp3(this.recorder.getWAV());
      // const fileOfBlob = new File([mp3Blob], new Date().getTime() + '.mp3')
      const fileOfBlob = new File([newbolb], this.userName + this.getNowDate() + '.wav')
      formData.append('file', fileOfBlob)
      // const url = window.URL.createObjectURL(fileOfBlob)
      // this.src = url
      // const axios = require('axios')
      // axios.post(url, formData).then(res => {
      //   console.log(res.data.data[0].url)
      // })
      uploadFile(formData).then(({ data }) => {
        if (data && data.data && data.code === 0) {
          this.$message("上传成功");
          this.dataForm.recordFilePath = data.data.url;
          this.recordFiles = data.data.id
        } else {
          this.$message.error(data.msg ? data.msg : "上传失败");
        }
      })
    },
    deleteRecord() {
      let id = this.recordFiles
      if (id != null && id != "") {
        // 后台删除 
        deleteFile(id).then(({ data }) => {
          if (data && data.code === 0) {
            this.dataForm.recordFilePath = ''
            this.$message("删除成功");
          }
        });
      }
    },
    // 控制音频的播放与暂停
    startPlayOrPause() {
      return this.audio.playing ? this.pause() : this.gplay()
    },
    // 播放音频
    gplay() {
      this.$refs.audio.play()
    },
    // 暂停音频
    pause() {
      this.$refs.audio.pause()
    },
    // 当音频播放
    onPlay() {
      this.audio.playing = true
    },
    // 当音频暂停
    onPause() {
      this.audio.playing = false
    },
    // 当timeupdate事件大概每秒一次，用来更新音频流的当前播放时间
    onTimeupdate(res) {
      console.log('timeupdate')
      console.log(res)
      this.audio.currentTime = res.target.currentTime
    },
    // 当加载语音流元数据完成后，会触发该事件的回调函数
    // 语音元数据主要是语音的长度之类的数据
    onLoadedmetadata(res) {
      console.log('loadedmetadata')
      console.log(res)
      this.audio.maxTime = parseInt(res.target.duration)
    },
    /**
     * 查询当天日期
     */
    getNowDate() {
      const timeOne = new Date()
      const year = timeOne.getFullYear()
      let month = timeOne.getMonth() + 1
      let day = timeOne.getDate()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      const NOW_MONTHS_AGO = `${year}-${month}-${day}`
      // this.jzrq = NOW_MONTHS_AGO
      return NOW_MONTHS_AGO
    },
  }
}
</script>
<style scoped>
.audioClass {
  /* height: 40px;
    width: 100px; */
  margin-top: -50px;
  margin-left: 330px;
}
</style>
